import classNames from "classnames";
import { useMemo, useState } from "react";
import { ElementWrapper } from "../../simples/ElementWrapper";
import styles from "./Report.module.scss";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendarIcon.svg";
import { thousand } from "../../../constants/helper";
import { ScrollBlock } from "../../simples/ScrollBlock";
import { TransactionsServices } from "../../../services/Transactions";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import ReportFilter, { TReportFilter } from "./parts/ReportFilter";
import ReactDatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import { Pagination } from "../../simples/Pagination";
import { reportsAPI } from "../../../services/Reports";

type TFilter = Partial<TReportFilter>;
enum Modals {
  Filter,
}

const dateToString = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const Report = () => {
  const [modal, setModal] = useState<Modals | null>(null);
  const [filter, setFilter] = useState<TFilter>({});

  const [datesStart, setDatesStart] = useState<Date>(() => new Date());
  const [datesEnd, setDatesEnd] = useState<Date>(() => new Date());

  const [offset, setOffsset] = useState<number>(0);
  const [totalOnPage, setTotalOnPage] = useState<number>(11);

  const from = useMemo(() => {
    return filter?.from
      ? dateToString(new Date(filter.from))
      : dateToString(datesStart);
  }, [filter, datesStart]);

  const to = useMemo(() => {
    return filter?.to
      ? dateToString(new Date(filter.to))
      : dateToString(datesEnd);
  }, [filter, datesEnd]);

  const { data, isFetching, isLoading } = reportsAPI.useFetchAllReportsQuery({
    limit: totalOnPage,
    offset: offset,
    from,
    to,
    ...filter,
  });

  const loading = useMemo(() => {
    return isFetching || isLoading;
  }, [isFetching, isLoading]);

  const totalLiters = useMemo(
    () =>
      data
        ? Math.round(data?.data?.reduce((prev, curr) => prev + +curr.value, 0))
        : 0,
    [data]
  );
  const totalSpend = useMemo(
    () =>
      data
        ? Math.round(data?.data?.reduce((prev, curr) => prev + +curr.price, 0))
        : 0,
    [data]
  );

  const onChangeTotalOnPage = (text: string) => {
    setTotalOnPage(+text);
  };

  const selectNumberPage = (pageNumber: number) => {
    setOffsset(totalOnPage * pageNumber - totalOnPage);
  };

  const onFilter = async (data: Partial<TReportFilter>) => {
    setFilter(data);
    setOffsset(0);
  };

  const exportFun = async () => {
    try {
      const response = await TransactionsServices.loadFullTransactions({
        download: 1,
        from: dateToString(datesStart),
        to: dateToString(datesEnd),
        spend: 1,
      });

      const link = document.createElement("a");
      link.href = response.fileName;
      link.setAttribute("download", "file.xls");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = () => {
    setModal(null);
  };

  const handleDatesStartChange = (date: Date) => {
    setDatesStart(date);
    if (!datesEnd || datesEnd < date) {
      setDatesEnd(new Date());
    }
  };

  const handleDatesEndChange = (date: Date) => {
    setDatesEnd(date);
  };

  return (
    <>
      <ScreenWrapper
        title={"История заправок"}
        isLoading={loading}
        classes={{ top: styles.top }}
      >
        <div className={styles.headerRight}>
          <ElementWrapper
            onClick={exportFun}
            pV={6}
            pG={10}
            className={styles.export}
          >
            Экспорт XLS
          </ElementWrapper>
          <ElementWrapper
            className={styles.filterButton}
            onClick={() => setModal(Modals.Filter)}
          >
            Фильтр
          </ElementWrapper>
          <div className={styles.datePickerGroup}>
            <div className={styles.datePickerGroup}>
              <ReactDatePicker
                selected={datesStart}
                onChange={handleDatesStartChange}
                dateFormat="yyyy-MM-dd"
                locale={ru}
              />
              <CalendarIcon className={styles.iconCalendar} />
            </div>
            <div className={styles.datePicker}>
              <ReactDatePicker
                selected={datesEnd}
                onChange={handleDatesEndChange}
                dateFormat="yyyy-MM-dd"
                locale={ru}
              />
              <CalendarIcon className={styles.iconCalendar} />
            </div>
          </div>
        </div>
        <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.table}>
              <div className={styles.header}>
                <div className={styles.headerItem}>Дата</div>
                <div className={styles.headerItem}>Время</div>
                <div className={styles.headerItem}>АГЗС</div>
                <div className={styles.headerItem}>ФИО</div>
                <div className={styles.headerItem}>Номер авто</div>
                <div className={styles.headerItem}>Кол-во литров</div>
                <div className={classNames(styles.headerItem, styles.cost)}>
                  Сумма
                </div>
              </div>
            </div>
            <ScrollBlock
              className={styles.scrollBlock}
              // onReachedEnd={fetchTrans}
            >
              {data?.data?.map((item, ind) => (
                <div className={styles.tr} key={`summary_${ind}`}>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.created_at.split(" ")[0]}
                  </div>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.created_at.split(" ")[1].slice(0, 5)}
                  </div>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.station}
                  </div>
                  <div className={styles.bodyItem}>
                    {item.driver?.fio || "Зачисление оплаты"}
                  </div>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.driver?.autoNum || "Зачисление оплаты"}
                  </div>
                  <div className={classNames(styles.bodyItem, styles.liters)}>
                    {item.value}
                  </div>
                  <div className={classNames(styles.bodyItem, styles.cost)}>
                    {thousand(+item.price)} ₽
                  </div>
                </div>
              ))}
            </ScrollBlock>
            <div className={styles.tableSum}>
              <div className={styles.result}>Итого:</div>
              <div className={classNames(styles.resultItem)}> </div>

              <div className={classNames(styles.resultItem)}>
                {thousand(totalLiters || 0)}
              </div>
              <div
                className={classNames(styles.resultItem)}
                style={{ justifyContent: "end", paddingRight: "34px" }}
              >
                {thousand(totalSpend || 0)} ₽
              </div>
            </div>
          </div>
        </div>

        <div className={styles.wrapperPagination}>
          <Pagination
            allQuantity={data?.totalCount ? +data.totalCount : 0}
            itemOnPage={totalOnPage}
            itemNumberPage={selectNumberPage}
            onChangeTotalOnPage={onChangeTotalOnPage}
            totalOnPage={totalOnPage}
          />
        </div>
      </ScreenWrapper>

      {modal === Modals.Filter && (
        <ReportFilter
          filters={filter}
          onSubmit={onFilter}
          onClose={onCloseModal}
        />
      )}
    </>
  );
};

export default Report;
