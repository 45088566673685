import classNames from "classnames";
import styles from "./History.module.scss";
import { useState } from "react";
import { ReactComponent as EditFilter } from "../../../assets/icons/settingFilter.svg";
import { thousand } from "../../../constants/helper";
import { transAPI } from "../../../services/Transactions";
import { TransType } from "../../../types/types";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import { ButtonWithSvg } from "../../simples/ButtonWithSvg";
import { Pagination } from "../../simples/Pagination";
import { HistoryModal } from "./parts/HistoryModal";
import { FilterHistoryType } from "./parts/HistoryModal/HistoryModal";
import { HistoryTabel } from "./parts/HistoryTabel";

function History() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterHistoryType>(
    {} as FilterHistoryType
  );

  const [totalOnPage, setTotalOnPage] = useState<number>(11);
  const [offset, setOffsset] = useState<number>(0);

  const onChangeTotalOnPage = (text: string) => {
    setTotalOnPage(+text);
  };

  const selectNumberPage = (pageNumber: number) => {
    setOffsset(totalOnPage * pageNumber - totalOnPage);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const getFilter = (data: FilterHistoryType) => {
    setFilter(data);
  };
  const { data } = transAPI.useFetchAllPostsQuery({
    limit: totalOnPage,
    offset: offset,
    start: filter.period?.split(" - ")[0].split(".").reverse().join("-")
      ? filter.period?.split(" - ")[0].split(".").reverse().join("-")
      : undefined,
    end: filter.period?.split(" - ")[1].split(".").reverse().join("-")
      ? filter.period?.split(" - ")[1].split(".").reverse().join("-")
      : undefined,
    spend: filter.removeBalance ? 1 : 0,
    income: filter.addBalance ? 1 : 0,
  });

  return (
    <>
      <ScreenWrapper title="История операций" classes={{ top: styles.top }}>
        <ButtonWithSvg
          onClick={openModal}
          classes={{ root: styles.filterButton }}
          icon={(props) => <EditFilter {...props} color={"#fff"} />}
        />
        <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.leftHeader}>
              <h3 className={styles.title}>Операции</h3>
              <span className={styles.date}>{filter.period}</span>
            </div>
            <div className={styles.historyWrapper}>
              <div className={styles.table}>
                <div className={styles.thead}>
                  <th className={styles.tableHeaderLineItem}>Дата</th>
                  <th className={styles.tableHeaderLineItem}>Приход</th>
                  <th className={styles.tableHeaderLineItem}>Расход</th>
                  <th
                    className={classNames(
                      styles.tableHeaderLineItem,
                      styles.headerItemLineEnd
                    )}
                  >
                    Транзакции
                  </th>
                </div>
                <div className={styles.tbody}>
                  {data?.data.map((item: TransType) => (
                    <HistoryTabel key={item.id} lineItem={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          {filter.period && (
            <div className={styles.grayWrapper}>
              <div className={styles.wrapperTable}>
                <td className={classNames(styles.result, styles.lineItem)}>
                  Итого:
                </td>

                <td className={classNames(styles.resultItem, styles.lineItem)}>
                  {" "}
                  {thousand(data?.totalIncome || 0)} ₽
                </td>
                <td className={classNames(styles.resultItem, styles.lineItem)}>
                  {" "}
                  {thousand(data?.totalSpend || 0)} ₽
                </td>
                <td className={classNames(styles.resultItem, styles.lineItem)}>
                  {""}
                </td>
              </div>
            </div>
          )}
        </div>

        <div
          className={styles.wrapperPagination}
          style={{
            marginTop: !filter.period ? "20px" : "77px",
          }}
        >
          <Pagination
            allQuantity={data?.count ? +data.count : 0}
            itemOnPage={totalOnPage}
            itemNumberPage={selectNumberPage}
            onChangeTotalOnPage={onChangeTotalOnPage}
            totalOnPage={totalOnPage}
          />
        </div>
      </ScreenWrapper>
      {showModal && <HistoryModal onClose={closeModal} onFilter={getFilter} />}
    </>
  );
}

export default History;
