import { BACK_PRODUCTION, Endpoints } from "../constants/constants";
import { toQueryString } from "../constants/helper";
import { RootTransType } from "../types/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { FetchFullTrans } from "./Transactions";

const baseUrl = BACK_PRODUCTION;

export const reportsAPI = createApi({
  reducerPath: "Reports",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", `application/json`);
      headers.set("Content-Type", `application/json`);
      return headers;
    },
  }),
  tagTypes: ["reports"],
  endpoints: (build) => ({
    fetchAllReports: build.query<RootTransType, FetchFullTrans>({
      query: (data) => {
        return {
          url: `${Endpoints.FULL_STATISTIC}${toQueryString(data)}`,
          params: {
            ...data,
          },
        };
      },
      providesTags: () => ["reports"],
    }),
  }),
});
