import React, {
  FC,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import styles from "./ModalClose.module.scss";

let container: any;
if (typeof window !== "undefined") {
  const modalParent = document.createElement("div");
  modalParent.className = styles.modalParent;
  document.body.appendChild(modalParent);

  container = modalParent;
}

type Props = {
  children: ReactNode;
  onClose?: () => void;
  onKeyDown?: KeyboardEventHandler;
  classes?: {
    root?: string;
    underlay?: string;
    modal?: string;
    close?: string;
    body?: string;
    title?: string;
  };
  title?: string | JSX.Element;
  close?: boolean;
};

const ModalClose: FC<Props> = ({
  onClose,
  classes = {},
  title,
  close = true,
  children,
  onKeyDown,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const closeESC = useCallback(
    (event: KeyboardEventHandler<HTMLDivElement> | any): void => {
      if (event.key === "Escape") {
        if (onClose) {
          onClose();
        }
      }
    },
    [onClose]
  );

  // Установка/удаление обработчика события на документе.
  useEffect(() => {
    document.addEventListener("keydown", closeESC, false);
    return () => {
      document.removeEventListener("keydown", closeESC, false);
    };
  }, [closeESC]);

  useEffect(() => {
    modalRef.current?.focus(); // Устанавливаем фокус на модалку при открытии
  }, []);

  return ReactDOM.createPortal(
    <div className={classNames(styles.root, classes.root)}>
      <div
        className={classNames(styles.underlay, classes.underlay)}
        onClick={onClose}
      />

      <div
        ref={modalRef}
        onKeyDown={onKeyDown}
        tabIndex={-1}
        className={classNames(styles.modal, classes.modal)}
      >
        <div className={classNames(styles.close, classes.close)}>
          <div className={classNames(styles.title, classes.title)}>{title}</div>
          {close && <Close onClick={onClose} className={styles.closeBtn} />}
        </div>

        <div className={classNames(styles.body, classes.body)}>{children}</div>
      </div>
    </div>,
    container
  );
};

export default ModalClose;
