export const _API_PREFIX = process.env.REACT_APP_API_URL;

export const BACK_PRODUCTION = "https://api.ggroup-ural.ru/"; // http
export const BACK_DEVELOPER = "https://88.198.56.78:8110/"; // http

export enum Endpoints {
  REGISTER = "api/v1/register-ul",
  ORG_DATA = "api/v1/da-data",
  LOGIN = "api/v1/legal/auth",
  GET_USER = "api/v1/legal",
  DRIVER = "api/v1/driver",
  TRANSACTIONS = "api/v1/legal/transactions",
  FULL_STATISTIC_BALLOONS = "/api/v1/legal/legal-history?ballons=1",
  SHORT_STATISTIC = "/api/v1/legal/short-statistic",
  FULL_STATISTIC = "/api/v1/legal/legal-history",
  STATIONS = "api/v1/stations",
  UPDATE_PROFILE = "api/v1/update-profile",
  PAYMENT = "api/v1/legal/payment-order",
  DELETE_DRIVER = "api/v1/remove-driver",
  GEN_CODE = "api/v1/gen-code",
  MAP_STATIONS = "api/v1/legal/stations",
  BLOCK_DRIVER = "api/v1/legal/driver-ban",
  UNBLOCK_DRIVER = "api/v1/legal/driver-unban",
}

export const DAYS_NAMES = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
export const DAYS_NAMES_FULL = [
  "Воскресенье",
  "Понедельник",
  "Вторник",
  "Среда",
  "Четверг",
  "Пятница",
  "Суббота",
];

export const DAY_IN_WEEK = 7;
export const _MONTHS = [
  {
    id: 1,
    label: "Январь",
    short: "Jan",
  },
  {
    id: 2,
    label: "Февраль",
    short: "Feb",
  },
  {
    id: 3,
    label: "Март",
    short: "Mar",
  },
  {
    id: 4,
    label: "Апрель",
    short: "Apr",
  },
  {
    id: 5,
    label: "Май",
    short: "May",
  },
  {
    id: 6,
    label: "Июнь",
    short: "Jun",
  },
  {
    id: 7,
    label: "Июль",
    short: "Jul",
  },
  {
    id: 8,
    label: "Август",
    short: "Aug",
  },
  {
    id: 9,
    label: "Сентябрь",
    short: "Sep",
  },
  {
    id: 10,
    label: "Октябрь",
    short: "Окт",
  },
  {
    id: 11,
    label: "Ноябрь",
    short: "Nov",
  },
  {
    id: 12,
    label: "Декабрь",
    short: "Dec",
  },
];

export const mailRe = /\S+@\S+\.\S{2}/;

export const emailDomens = [
  "gmail.com",
  "mail.ru",
  "inbox.ru",
  "vars.ru",
  "yandex.ru",
  "ya.ru",
];

export const colors: string[] = ["#FE435B", "#20C997"];

export enum EnvType {
  PRODUCTION = "production",
  DEV = "development",
}
