import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { ElementWrapper } from "../../simples/ElementWrapper";
import styles from "./Ballons.module.scss";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendarIcon.svg";
import { thousand } from "../../../constants/helper";
import { ScrollBlock } from "../../simples/ScrollBlock";
import { TransactionsServices } from "../../../services/Transactions";
import { TransFullType } from "../../../types/types";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import ReportFilter, { TReportFilter } from "./parts/BallonsFilter";
import ReactDatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";

type TFilter = Partial<TReportFilter>;
enum Modals {
  Filter,
}

const Ballons = () => {
  const [modal, setModal] = useState<Modals | null>(null);
  const [list, setList] = useState<TransFullType[]>([]);
  const [filter, setFilter] = useState<TFilter>({});
  const [loading, setLoading] = useState(false);
  const has = useRef(true);
  const offset = useRef({ curr: 0, max: 0 });
  const [datesStart, setDatesStart] = useState<Date>(new Date());
  const [datesEnd, setDatesEnd] = useState<Date>(new Date());

  const totalLiters = useMemo(
    () => Math.round(list.reduce((prev, curr) => prev + +curr.value, 0)),
    [list]
  );
  const totalSpend = useMemo(
    () => Math.round(list.reduce((prev, curr) => prev + +curr.price, 0)),
    [list]
  );

  const onFilter = async () => {
    setList([]);
    has.current = true;
    setLoading(true);
    offset.current = { curr: 0, max: 0 };
    await fetchTrans();
  };

  const fetchTrans = async () => {
    const limit = 15;
    try {
      const response = await TransactionsServices.lazyFullBallonsTransactions({
        limit,
        offset: offset.current.curr,
        from: dateToString(datesStart),
        to: dateToString(datesEnd),
        ...filter,
      });
      offset.current.curr += response.data.length;
      offset.current.max = offset.current.curr + +response.totalCount;

      setList((prev) => [...prev, ...response.data]);
      has.current = response.data.length === limit;
    } catch (error) {
      has.current = false;
    } finally {
      setLoading(false);
    }
  };

  const exportFun = async () => {
    try {
      const response = await TransactionsServices.loadFullBallonsTransactions({
        download: 1,
        from: dateToString(datesStart),
        to: dateToString(datesEnd),
        spend: 1,
      });

      const link = document.createElement("a");
      link.href = response.fileName;
      link.setAttribute("download", "file.xls");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const dateToString = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  useEffect(() => {
    fetchTrans();
  }, [datesStart, datesEnd, filter]);

  const onCloseModal = () => {
    setModal(null);
  };

  const handleDatesStartChange = (date: Date) => {
    setDatesStart(date);
    if (!datesEnd || datesEnd < date) {
      setDatesEnd(new Date());
    }
    onFilter();
  };

  const handleDatesEndChange = (date: Date) => {
    setDatesEnd(date);
    onFilter();
  };

  return (
    <>
      <ScreenWrapper
        title={"История баллонов"}
        isLoading={loading}
        classes={{ top: styles.top }}
      >
        <div className={styles.headerRight}>
          <ElementWrapper
            onClick={exportFun}
            pV={6}
            pG={10}
            className={styles.export}
          >
            Экспорт XLS
          </ElementWrapper>
          <ElementWrapper
            className={styles.filterButton}
            onClick={() => setModal(Modals.Filter)}
          >
            Фильтр
          </ElementWrapper>
          <div className={styles.datePickerGroup}>
            <div className={styles.datePickerGroup}>
              <ReactDatePicker
                selected={datesStart}
                onChange={handleDatesStartChange}
                dateFormat="yyyy-MM-dd"
                locale={ru}
              />
              <CalendarIcon className={styles.iconCalendar} />
            </div>
            <div className={styles.datePicker}>
              <ReactDatePicker
                selected={datesEnd}
                onChange={handleDatesEndChange}
                dateFormat="yyyy-MM-dd"
                locale={ru}
              />
              <CalendarIcon className={styles.iconCalendar} />
            </div>
          </div>
        </div>
        <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.table}>
              <div className={styles.header}>
                <div className={styles.headerItem}>Дата</div>
                <div className={styles.headerItem}>Время</div>
                <div className={styles.headerItem}>АГЗС</div>
                <div className={styles.headerItem}>ФИО</div>
                <div className={styles.headerItem}>Номер авто</div>
                <div className={styles.headerItem}>Кол-во литров</div>
                <div className={classNames(styles.headerItem, styles.cost)}>
                  Сумма
                </div>
              </div>
            </div>
            <ScrollBlock
              className={styles.scrollBlock}
              onReachedEnd={fetchTrans}
            >
              {list.map((item, ind) => (
                <div className={styles.tr} key={`summary_${ind}`}>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.created_at.split(" ")[0]}
                  </div>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.created_at.split(" ")[1].slice(0, 5)}
                  </div>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.station}
                  </div>
                  <div className={styles.bodyItem}>
                    {item.driver?.fio || "Зачисление оплаты"}
                  </div>
                  <div
                    className={classNames(styles.bodyItem, styles.gosNumber)}
                  >
                    {item.driver?.autoNum || "Зачисление оплаты"}
                  </div>
                  <div className={classNames(styles.bodyItem, styles.liters)}>
                    {item.value}
                  </div>
                  <div className={classNames(styles.bodyItem, styles.cost)}>
                    {thousand(+item.price)} ₽
                  </div>
                </div>
              ))}
            </ScrollBlock>
            <div className={styles.tableSum}>
              <div className={styles.result}>Итого:</div>
              <div className={classNames(styles.resultItem)}> </div>
              <div className={classNames(styles.resultItem)}>
                {thousand(totalLiters || 0)}
              </div>
              <div
                className={classNames(styles.resultItem)}
                style={{ justifyContent: "end", paddingRight: "34px" }}
              >
                {thousand(totalSpend || 0)} ₽
              </div>
            </div>
          </div>
        </div>
      </ScreenWrapper>
      {modal === Modals.Filter && (
        <ReportFilter
          filters={filter}
          onSubmit={onFilter}
          onClose={onCloseModal}
        />
      )}
    </>
  );
};

export default Ballons;
