import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import StatIcon from "../../../assets/icons/staticon.svg";
import { Icon } from "leaflet";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import styles from "./Map.module.scss";

interface Station {
  id: number;
  name: string;
  address: string;
  coords: string;
  yandex_id: string;
  work_period: string;
  car_price: string;
  balloon_price: string;
}

const MapComponent: React.FC = () => {
  const [stations, setStations] = useState<Station[]>([]);
  const [selectedStationId, setSelectedStationId] = useState<number | null>(
    null
  );

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get(
          "https://api.ggroup-ural.ru/api/v1/legal/stations"
        );
        setStations(response.data.data);
      } catch (error) {
        console.error("Error fetching stations:", error);
      }
    };

    fetchStations();
  }, []);

  const getCoordsArray = (coordsString: string): [number, number] => {
    const parts = coordsString
      .split(",")
      .map((part) => parseFloat(part.trim()));
    return [parts[1], parts[0]];
  };

  const legalIcon = new Icon({
    iconUrl: StatIcon,
    iconSize: [35, 35],
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76],
  });

  return (
    <ScreenWrapper
      title={"Адреса наших АГЗС"}
      classes={{ content: styles.container }}
    >
      <MapContainer
        attributionControl={false}
        center={[56.833332, 60.583332]}
        zoom={10}
        className={styles.inner}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {stations.map((station, i) => {
          return !!station.coords ? (
            <Marker
              key={station.id}
              position={getCoordsArray(station.coords)}
              eventHandlers={{
                click: () => setSelectedStationId(station.id),
              }}
              icon={legalIcon}
            >
              {selectedStationId === station.id && (
                <Popup>
                  <div>
                    <h2>{station.name}</h2>
                    <p>
                      <strong>Адрес:</strong> {station.address}
                    </p>
                    <p>
                      <strong>Время работы:</strong> {station.work_period}
                    </p>
                    <p>
                      <strong>Стоимость литра газа:</strong> {station.car_price}{" "}
                      руб.
                    </p>
                    <p>
                      <strong>Стоимость литра баллона:</strong>{" "}
                      {station.balloon_price} руб.
                    </p>
                  </div>
                </Popup>
              )}
            </Marker>
          ) : (
            <React.Fragment key={station.id}></React.Fragment>
          );
        })}
      </MapContainer>
    </ScreenWrapper>
  );
};

export default MapComponent;
