import classNames from "classnames";
import { FC, forwardRef, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import styles from "./Input.module.scss";
import { ReactComponent as Eye } from "../../../assets/icons/eyePassword.svg";
import { ReactComponent as Pensile } from "../../../assets/icons/pencile.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { validInputNumber } from "../../../constants/helper";

export const InputAfetIcons = {
  TRASH: <Trash />,
  PENSIL: <Pensile />,
};

export enum TypeInput {
  PASSWORD = "password",
  NUMBER = "number",
  NONE = "none",
}
export type InputProps = {
  onChange: (e: string) => void;
  value: string | number;
  disabled?: boolean;
  placeholder?: string;
  classes?: {
    input?: string;
    root?: string;
    inputWrapper?: string;
  };
  mask?: string | any;
  error?: string | undefined;
  label?: string;
  ml?: number;
  before?: string;
  afterIcon?: React.ReactNode;
  typeProps?: TypeInput;
  onIconPress?: () => void;
  color?: string;
  autoComplete?: string;
};
const Input: FC<InputProps> = forwardRef<
  HTMLInputElement & InputMask,
  InputProps
>(
  (
    {
      onChange,
      value,
      disabled,
      placeholder,
      classes,
      mask,
      label,
      before,
      afterIcon,
      autoComplete,
      error,
      ml,
      typeProps = TypeInput.NONE,
      onIconPress,
    },
    ref
  ) => {
    const [pass, SetPass] = useState<boolean>(true);

    const clickEye = () => {
      SetPass((prev) => !prev);
    };
    const typeFun = useMemo(() => {
      return !pass && typeProps === TypeInput.PASSWORD
        ? TypeInput.NONE
        : typeProps;
    }, [typeProps, pass]);

    const modeinput = (str: string) => {
      if (typeProps === TypeInput.NUMBER) {
        onChange(validInputNumber(str));
      } else {
        onChange(str);
      }
    };

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      if (mask === "a 999 aa") {
        // валидация гос номера
        value = e.target.value?.replace(/[qwriusdfgjlzvnQWRIUSDFGJLZVN]+/g, "");
      }

      onChange(value);
    };

    return (
      <form
        className={classNames(styles.root, classes?.root)}
        autoComplete={autoComplete}
        onSubmit={(e) => e.preventDefault()}
      >
        {label && <div className={styles.label}>{label}</div>}
        <div className={styles.container}>
          <div
            className={classNames(styles.inputWrapper, classes?.inputWrapper, {
              [styles.error]: !!error,
            })}
          >
            {before && <span className={styles.before}>{before}</span>}
            {!mask ? (
              <div
                style={{
                  width: "100%",
                }}
              >
                <input
                  ref={ref}
                  type={typeFun}
                  maxLength={ml ?? ml}
                  disabled={disabled}
                  className={classNames(
                    styles.input,
                    {
                      [styles.disabled]: disabled,
                    },
                    classes?.input
                  )}
                  placeholder={placeholder}
                  onChange={(e) => modeinput(e.target.value)}
                  value={value}
                  min={0}
                />
                {typeProps === TypeInput.PASSWORD && (
                  <Eye className={styles.eye} onClick={clickEye} />
                )}
              </div>
            ) : (
              <InputMask
                ref={ref}
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                //@ts-ignore - bad types of lib
                maskChar={null}
                onChange={onChangeHandler}
                mask={mask}
                className={classNames(
                  styles.input,
                  {
                    [styles.disabled]: disabled,
                    // [styles.error]: !!error
                  },
                  classes?.input
                )}
              />
            )}
          </div>
          {afterIcon && (
            <button
              disabled={!onIconPress}
              onClick={onIconPress}
              className={styles.afterIconButton}
            >
              {afterIcon}
            </button>
          )}
        </div>
        {error && <div className={styles.errorText}>{error}</div>}
      </form>
    );
  }
);

export default Input;
