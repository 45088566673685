import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import styles from "./DriversEdit.module.scss";
import { Drivers as driversServices } from "../../../services/Drivers";
import { DriverCard } from "../../complexes/DriverCard";
import { Driver } from "../../../types/driver";
import { initialDriver } from "../../../constants/driver";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { setIsLoading } from "../../../store/slice/app";

export enum PeriodLiters {
  DAY = "day",
  MONTH = "month",
}

function DriversEdit() {
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState<Driver>(initialDriver);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.app.isLoading);

  /**
   * get data
   */
  const getDriver = useCallback(async () => {
    if (id) {
      dispatch(setIsLoading(true));
      const response = await driversServices.getByDriversId(+id);
      const driver = response?.driverProfile;
      setData({
        ...initialDriver,
        autoMark: driver?.autoMark || "",
        autoNum: driver?.autoNum || "",
        email: response?.email || "",
        phone: driver.phone || "",
        fio: driver.fio || "",
        limitLitresDay: driver.limitLitresDay
          ? String(driver.limitLitresDay)
          : "",
        limitLitresMonth: driver.limitLitresMonth
          ? String(driver.limitLitresMonth)
          : "",
        stationsBan: driver.stationsBan.map((el) => el.station),
        codeAuto: response.codeAuto || "",
        blocked: response.blocked,
      });
      dispatch(setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    getDriver();
  }, [id]);

  const submit = (driver: Driver) => {
    dispatch(setIsLoading(true));
    id &&
      driversServices
        .editDrivers(+id, {
          email: driver.email,
          codeAuto: driver.codeAuto,
          password: driver.password || undefined,
          stations_ids: driver.stationsBan.map((el) => el.id),
          phone: driver.phone,
          fio: driver.fio,
          autoNum: driver.autoNum,
          autoMark: driver.autoMark,
          limitLitresDay: +driver.limitLitresDay,
          limitLitresMonth: +driver.limitLitresMonth,
        })
        .then((res) => {
          dispatch(setIsLoading(false));
          res.id && navigate(-1);
        });
  };

  return (
    <>
      <ScreenWrapper isLoading={isLoading} title={"Карточка водителя"}>
        <div className={styles.editWrapper}>
          <DriverCard
            isEdditting={true}
            onSubmit={submit}
            initialState={data}
            getDriver={getDriver}
            buttonText={"Сохранить изменения"}
          />
        </div>
      </ScreenWrapper>
    </>
  );
}

export default DriversEdit;
