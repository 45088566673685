import { useEffect, useState } from "react";
import { initialDriver } from "../../../constants/driver";
import { InputErrors } from "../../../constants/valids";
import { Driver, ValidDriverErrors } from "../../../types/driver";
import { validateEmail } from "../../../utils/valid";
import { Button } from "../../simples/Button";
import styles from "./DriverCard.module.scss";
import LeftSiteDriverCard from "./parts/LeftSiteDriverCard";
import RightPartDriverCard from "./parts/RighPartDriverCard";

type Props = {
  onSubmit: (data: Driver) => void;
  buttonText?: string;
  backError?: ValidDriverErrors | undefined;
  initialState: Driver;
  isEdditting?: boolean;
  getDriver?: () => Promise<void>;
};

const checkingType = [
  "fio",
  "phone",
  "autoNum",
  "password",
  "email",
  "codeAuto",
];

const checkingEditType = ["fio", "phone", "autoNum", "email", "codeAuto"];

const DriverCard = ({
  initialState,
  buttonText,
  backError,
  onSubmit,
  isEdditting,
  getDriver,
}: Props) => {
  const [data, setData] = useState<Driver>(initialDriver);
  const [errors, setErrors] = useState<ValidDriverErrors>({});

  const setFieldOfData = (field: keyof Driver, value: any) => {
    setData((prevState) => ({ ...prevState, [field]: value }));
    errors &&
      setErrors((prev) => {
        delete prev[field];
        return prev;
      });
  };

  const submit = () => {
    validAllFields() && onSubmit(data);
  };

  const validAllFields = () => {
    let isValid = true;

    //Is bad email
    if (validateEmail(data.email || "") === null) {
      isValid = false;
      errors
        ? setErrors((prev) => ({ ...prev, email: [InputErrors.BAD_EMAIL] }))
        : setErrors({ email: [InputErrors.BAD_EMAIL] });
    }

    if (data.phone.length < 17) {
      errors
        ? setErrors((prev) => ({ ...prev, phone: [InputErrors.BAD_LEN] }))
        : setErrors({ phone: [InputErrors.EMPTY] });
    }

    if (data.autoNum.length < 8) {
      errors
        ? setErrors((prev) => ({ ...prev, autoNum: [InputErrors.BAD_LEN] }))
        : setErrors({ email: [InputErrors.BAD_LEN] });
    }

    Object.values(isEdditting ? checkingEditType : checkingType).map(
      (dataKey: string) => {
        const value = data[dataKey as keyof ValidDriverErrors];
        const valueLength = typeof value === "string" ? value?.length : 0;
        //is too small
        if (valueLength < 3) {
          errors
            ? setErrors((prev) => ({
                ...prev,
                [dataKey]: [InputErrors.BAD_LEN],
              }))
            : setErrors({ [dataKey]: [InputErrors.BAD_LEN] });
          isValid = false;
        }
        //Is empty
        if (!valueLength) {
          isValid = false;
          errors
            ? setErrors((prev) => ({ ...prev, [dataKey]: [InputErrors.EMPTY] }))
            : setErrors({ [dataKey]: [InputErrors.EMPTY] });
        }
      }
    );

    return isValid;
  };

  // set initial data;
  useEffect(() => {
    setData(initialState);
  }, [initialState]);

  //set errors from back
  useEffect(() => {
    backError &&
      setErrors((prev) => (prev ? { ...prev, ...backError } : backError));
  }, [backError]);

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <LeftSiteDriverCard
          isEdditing={isEdditting}
          errors={errors}
          setData={setFieldOfData}
          data={data}
        />
        <RightPartDriverCard
          isEdditing={isEdditting}
          errors={errors}
          data={data}
          getDriver={getDriver}
          setData={setFieldOfData}
        />
      </div>
      <div className={styles.button}>
        <Button onClick={submit} disabled={!!Object.keys(errors).length}>
          {buttonText || "Применить"}
        </Button>
      </div>
    </div>
  );
};

export default DriverCard;
