import { Driver } from "../types/driver";

export const initialDriver: Driver = {
  phone: "",
  fio: "",
  limitLitresDay: "",
  limitLitresMonth: "",
  autoMark: "",
  autoNum: "",
  codeAuto: "",
  stationsBan: [],
  password: "",
  blocked: 0,
};
